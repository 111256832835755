.et-pdf-content-el {
  background-color: rgb(255, 255, 128, 0.5);
}

.et-pdf-content-el:hover {
  background-color: rgb(255, 0, 0, 1);
  z-index: 110;
}

/*
.et-pdf-render span {
  z-index: 100;
}
*/
.et-pdf-render div {
  z-index: 100;
}

.et-pdf-selected-el[selection='1'] {
  background-color: rgba(0, 255, 0, 0.5);
}

.et-pdf-selected-el[selection='2'] {
  background-color: rgba(0, 0, 255, 0.5);
}

/*
.et-pdf-selected-parent {
  z-index: 90 !important;
}
*/

.et-pdf-nearest-parent {
  border: solid 2px black !important;
  display: block !important;
}

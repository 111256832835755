$enable-shadows: true;
$enable-gradients: false;
$enable-responsive-font-sizes: true;

$et-blue: #195E88;
$primary: $et-blue;

$wasabi: #00ae42;
$wasabi-60: #028534;
$success: $wasabi-60;

$honey: #ffb30f;
$honey-60: #916404;
$warning: $honey;

$ruby: #a4031f;
$danger: $ruby;

$black: black;
$white: white;

$navbar-light-color: rgba($black, 0.8);
$navbar-light-hover-color: rgba($black, 0.9);

$navbar-dark-color: rgba($white, 0.8);
$navbar-dark-hover-color: rgba($white, 0.9);

@import 'variables';
@import '~bootstrap/scss/bootstrap';

.nav-item.nav-link.active,
.active.nav-link {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

.tab-content {
  margin-top: 1rem;
}

.et-container {
  max-width: 1336px;
  padding: 0.25em 0.5rem;
  min-height: 70vh;
  margin-top: 2em;
  background-color: white;
}

.btn {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

.toast-header {
  .close {
    color: white;
  }
}

.modal-header {
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 1.25rem;
  padding: 0.75rem 1.25rem;
  .close {
    &:focus {
      outline: none;
    }
    span {
      vertical-align: middle;
    }
  }
}

/* Custom Edit Button */
.btn-edit {
  color: #00ae42;
  background-color: #fff;
  border-color: #00ae42;
}
.btn-edit:hover {
  color: #fff;
  background-color: #007b2f;
  border-color: #00ae42;
}
.btn-edit:focus,
.btn-edit.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 186, 94, 0.5);
}
.btn-edit.disabled,
.btn-edit:disabled {
  color: #00ae42;
  background-color: #fff;
  border-color: #00ae42;
}

.btn-edit:not(:disabled):not(.disabled):active,
.btn-edit:not(:disabled):not(.disabled).active,
.show > .btn-edit.dropdown-toggle {
  color: #00ae42;
  background-color: #007b2f;
  border-color: #00ae42;
}
.btn-edit:not(:disabled):not(.disabled):active:focus,
.btn-edit:not(:disabled):not(.disabled).active:focus,
.show > .btn-edit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 186, 94, 0.5);
}
/* End Custom Edit Button */

/* Custon Navlink tab for bills */
.et-bill-tab {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.et-bill-tab > a {
  border-radius: 0 !important;
}

.et-bill-tab > a:hover {
  background-color: #195E88;
  color: white;
}

/* end custon navlinks */
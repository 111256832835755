:root {
  --et_green: #73B043;
  --et_blue: #195E88;
  --et_grey: #5C5C5C;
  --et_orange: #E9B44B;
  --et_purple: #554F7D;
  --et_red: #DB2B39;
  --et_light_blue: #93B7BE;
  --et_light_grey: #B4B4B4;
  --et_dark_blue:#173753;
  --et_dark_green:#015f25;
  --et_hover_blue:#0d3147;
  --et_border_grey:#dee2e6;

  --et_tab_grey:#707070;
  --et_tab_grey_selected:#525252;

  --et_blue_rgb:25,95,136
}

.App {
  /* text-align: center; */
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 50px;
}

html,
body {
  height: 100%;
  position: relative;
}

.bg-primary {
  background-color: var(--et_blue) !important;
}

.btn-primary {
  background-color: var(--et_blue) !important;
  border-color: var(--et_blue) !important;
}

.btn-primary:hover{
  background-color: var(--et_dark_blue) !important;
    border-color: var(--et_dark_blue) !important;
}

.btn-primary:disabled {
  pointer-events: none;
}


.btn-success {
  background-color: var(--et_green) !important;
  border-color: var(--et_green) !important;
}

.btn-success:hover {
  background-color: var(--et_dark_green) !important;
  border-color: var(--et_dark_green) !important;
}

.btn-success:disabled{
  pointer-events: none;
}


.et-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 32px;
  margin-top: 20px;
}

.loader {
  font-size: 8em;
  color: #419a1c;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.newBill {
  margin: 0;
  padding: 0px 0px 0px 0px;
}

.react-bootstrap-table-pagination-list {
  text-align: center;
  transform: translateX(-100%);
}

.react-bootstrap-table-page-btns-ul {
  float: none;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.triangle-bottom-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 45vh 45vh 0;
  border-color: transparent transparent var(--et_green) transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -10;
}

.triangle-bottom-left-bg {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50vh 50vh 0;
  border-color: transparent transparent var(--et_blue) transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -11;
}

.recharts-surface {
  overflow: visible;
}

table.hide-final-column tr td:last-child,
table.hide-final-column tr th:last-child {
  display: none;
}

.grecaptcha-badge {
  z-index: 1;
  bottom: 30px !important;
}

.login-cardbody {
  margin-top: 0px;
  padding: 1.25rem 2rem 2rem;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--et_border_grey);
}

.accordion-rounded-0 > .card {
  border-radius: 0;
}
.accordion-rounded-bottom-0 > .card {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-rounded-top-0 > .card {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

/* Hide Freshworks widget launcher button */
#launcher-frame {
  display: none !important;
}

.et-table-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}

.et-table-wrapper-short {
  max-height: 45vh;
  overflow-y: auto;
}

.et-table-header {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.et-table-header + tbody {
  position: sticky;
  top: 34px;
  background: white;
}

/* styling to stop disappearing borders*/
.et-table-header > tr > th {
  border-top: 0 !important;
  border-bottom: 0 !important ;
  padding: 5px 0;
  box-shadow: inset 0 2px var(--et_border_grey), inset 0 -2px var(--et_border_grey);
}

.et-table-header + tbody > tr > th {
  border-top: 0 !important;
  border-bottom: 0 !important ;
}

.et-table-header + tbody {
  box-shadow: inset 0 -2px var(--et_border_grey);
}

.et-table-selected-row {
  background-color:rgba(var(--et_blue_rgb),0.3);

}


/* custom modal styling */
.et-sub-large-modal .modal-content {
  width: 70%;
  left: 20%;
}



/* sub tab styling for additional analysis page */
.et-sub-tab-content > .tab-content {
  min-height: 500px;
  border: 1px solid rgb(222, 226, 230);
  border-top: none;
  margin-top: 0px;
  padding-bottom: 1em;
}


/* End styling for additional analysis page */

.et-nav-0 {
  padding: 0 !important;
}

.et-table-filter-row > tbody > tr > th {
  text-align: center;
}

.et-toast-buttons + div > div > button {
  width: 100%;
  margin-top: 0.5em;
}

/* home page */
.et-home-row {
  width: 80%;
  margin-left: 10% !important;
}

.et-non-active-button.active {
  color: white !important;
  font-weight: unset !important;
  background-color: var(--et_blue) !important;
  border-color: var(--et_blue) !important;
}


.et-non-active-button.active:hover {
  background-color: var(--et_dark_blue) !important;
  border-color: var(--et_dark_blue) !important;
}
.et-non-active-anchor.active {
  font-weight: unset !important;
}

/* end home page */

.et-custom-summary::marker {
  color: grey;
}

.et-regression-event-table-cont>div>table>thead {
  text-align: center;
}

/* fake anchor */
.et-link {
  cursor: pointer;
  color: var(--et_blue);
}

.et-link:hover{
  color: var(--et_hover_blue);
  text-decoration: underline;
}



/* custom breadcrumb styling */
.et-breadcrumb ol{
  background-color: unset;
  padding-top: 0;padding-bottom: 0;padding-left: 0;
  margin-bottom: 0;
}


/* ET Main Tab Styling */

.et-main-tab-content > .tab-content {
  margin-top: 0;
}

.et-main-tab-content > .tab-content > div{
    padding: 1em 1.5em;
    min-height: 70vh;
    border-top-left-radius: 0;
}

.et-main-tab-content[tabtype='modal']>.tab-content>div {
  min-height: 30vh;
}

.et-main-tab-content[tabtype='medium']>.tab-content>div {
  min-height: 50vh;
}

.et-main-tab-content[tabtype='flex']>.tab-content>div {
  min-height: 0;
  padding-bottom: 30px;
}



/* tab selection styles */

.et-main-tabs{
  border: unset !important;
}

.et-main-tabs a {
  color: var(--et_tab_grey);
}

.et-main-tabs a[aria-selected="true"] {
  border-color: white white var(--et_border_grey) !important;
  border-bottom: solid 1px;
  padding-bottom: 0;
  color: var(--et_tab_grey_selected)
}

.et-main-tabs a[aria-disabled="true"] {
  opacity: 0.5;
}

.et-main-tabs a:hover {
  border-color: white white var(--et_tab_grey) !important;
  color: var(--et_tab_grey_selected) !important;
}

/* END ET Main Tab Styling */


/* sub tab styling */

.et-sub-tab-content a {
  color: var(--et_tab_grey)
}

.et-sub-tab-content a[aria-selected="true"] {
  color: var(--et_tab_grey_selected)
}

.et-sub-tab-content a[aria-disabled="true"] {
  opacity: 0.5;
}

.et-sub-tab-content a:hover {
  color: var(--et_tab_grey_selected) !important
}

.et-second-tabs a {
  padding-left: 0px;
  padding-right: 2em;
  color: var(--et_tab_grey)
}

.et-second-tabs a[aria-selected="true"] {
  border-color: white white var(--et_border_grey) !important;
  color: var(--et_tab_grey_selected)
}

.et-second-tabs a[aria-disabled="true"] {
  opacity: 0.5;
}

.et-second-tabs a:hover {
  border-color: white white var(--et_border_grey) !important;
  font-weight: 600;
  color: var(--et_tab_grey_selected)
}

.react-datepicker__navigation-icon--previous::before{
  border-color: #aaa !important;
}

.react-datepicker__navigation-icon--next::before{
  border-color: #aaa !important;
}



.et-password-input-group-button:hover {
  background-color: unset !important;
}


/* react select styling */

.et-react-select__menu {
  z-index: 999 !important;
  border-radius: 0 !important;
  margin-top: 0 !important;
}

.et-react-select__option{
  padding: 0.05em 0.75em !important;
}


.et-react-select__option:hover,
.et-react-select__option:focus,
.et-react-select__option[aria-selected="true"] {
background-color: var(--et_blue) !important;
color: hsl(0, 0%, 100%) !important;
}


/* Login seperator
https: //stackoverflow.com/a/26634224
*/
.et-separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.et-separator::before,
.et-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--et_tab_grey);
}

.et-separator:not(:empty)::before {
  margin-right: .25em;
}

.et-separator:not(:empty)::after {
  margin-left: .25em;
}
@media screen and (max-width: 700px) {
  /*homepage */
  .et-home-footer-logo-cont {
    display: none;
  }
  .et-home-footer-copyright {
    display: none;
  }
  .et-home-bar-button-cont > * {
    margin-left: 0.125em !important;
    margin-right: 0.125em !important;
  }
  .et-home-bar-logo-cont {
    width: 75vw;
    text-align: center;
  }
  .et-home-quote {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .et-home-feature-text {
    height: 90px !important;
  }

  /*brandbar */
  .et-brandbar-navbar {
    padding-top: 0px !important;
  }
  .et-brandbar-logo {
    height: 50px;
  }

  /* tab bars */
  .et-report-building-select-cont {
    padding: 1em 0;
  }
  .et-dashboard-date-select-cont {
    padding: 1em 0;
  }

  /*login */
  .et-unauth-container {
    width: unset !important;
  }
}
